import request from '@/utils/request';

// 进油上报记录
export function getOilinRecord(params) {
  return request({
    url: '/oilin/oil_record',
    method: 'GET',
    params
  });
}

export function ExportOilinRecord(params) {
  return request({
    url: '/oilin/oil_record_export',
    method: 'get',
    responseType: 'blob',
    params
  });
}

// 进油汇总
export function getOilinSum(params) {
  return request({
    url: '/oilin/oil_sum',
    method: 'GET',
    params
  });
}

export function ExportOilinSum(params) {
  return request({
    url: '/oilin/oil_sum_export',
    method: 'get',
    responseType: 'blob',
    params
  });
}

/**
 * 液位仪采集的进油记录
 * @param {site_id, tank_num, oil_name, begin, end } params - 查询参数
 * @returns - Promise
 */
export function queryOilEntryRecord(params) {
  return request({
    url: '/oil_inlet_record/data',
    method: 'post',
    params
  });
}

/**
 * 液位仪采集的进油记录
 * @param {*} params - 导出数据的过滤条件
 * @returns - Promise
 */
export function exportOilEntryRecord(params) {
  return request({
    url: '/oil_inlet_record/export',
    method: 'get',
    responseType: 'blob',
    params
  });
}
