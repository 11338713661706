<template>
  <page-header :title="title" :breadcrumbs="pathMatch">
    <template v-slot:searchbar>
      <el-form inline size="mini">
        <AreaField v-model="searchParams.area_code" @change="getStationList" />
        <el-form-item>
          <el-select
            v-model="searchParams.site_id"
            filterable
            :clearable="true"
            placeholder="加油站(可输入)"
          >
            <el-option
              v-for="item in gasData"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            style="width: 100%"
            v-model="searchParams.oil_name"
            placeholder="请选择油品"
            :clearable="true"
          >
            <el-option
              v-for="(item, index) in typeOptions"
              :key="index"
              :label="item.type"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            style="width: 100%"
            v-model="searchParams.tank_num"
            placeholder="请选择油罐号"
            :clearable="true"
          >
            <el-option
              v-for="idx in 10"
              :key="idx"
              :label="idx"
              :value="idx"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="date"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            placeholder="请选择"
            clearable
            @change="getTime"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="handleQuery"
            >查询</el-button
          >
          <el-button
            type="primary"
            icon="el-icon-arrow-up"
            size="mini"
            @click="handleExport"
            class="export-btn"
            >导出</el-button
          >
        </el-form-item>
      </el-form>
    </template>

    <default-table
      :columns="columns"
      :dataSource="data"
      :pagination="pagination"
      :loading="tableLoading"
      @currentChange="handleCurrentChange"
    >
      <template #index="{ scope }">{{ scope.$index + 1 }}</template>
      <template #cerImg="{ scope }">
        <el-image
          :src="scope.row.cerImg"
          style="width: 60px; height: 40px"
          :preview-src="[scope.row.cerImg]"
        ></el-image>
      </template>
    </default-table>
  </page-header>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import { queryOilEntryRecord, exportOilEntryRecord } from '@/api/oilin';

import DefaultTable from '@/components/DefaultTable/new';
import { columns } from './columns/OilinRecord';
// import { getGasData } from '@/api/global';
import mixins from '@/mixins';
import { getStorage } from '@/utils/storage';

export default {
  components: {
    PageHeader,
    DefaultTable
  },
  data() {
    return {
      site_id: getStorage('site_id'),
      typeOptions: [
        { type: '92#', value: '92#' },
        { type: '95#', value: '95#' },
        { type: '93#', value: '93#' },
        { type: '97#', value: '97#' },
        { type: '98#', value: '98#' },
        { type: '101#', value: '101#' },
        { type: '0#', value: '0#' },
        { type: '5#', value: '5#' },
        { type: '-10#', value: '-10#' },
        { type: '-20#', value: '-20#' },
        { type: '-35#', value: '-35#' },
        { type: '-50#', value: '-50#' }
      ],
      tableLoading: true,
      searchParams: {},
      columns,
      data: [],
      date: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10
      }
    };
  },
  computed: {
    title() {
      const currentName = this.$router.history.current.name;
      return this.$locales[currentName];
    }
  },
  mixins: [mixins],
  created() {
    this.pathMatch = this.$router.history.current.matched;
    this.handleQuery({
      current: 1,
      pageSize: 10
    });
  },
  methods: {
    handleQuery({ current, pageSize }) {
      this.tableLoading = true;
      this.pagination.current = current || 1;
      const params = {
        page: current || 1,
        limit: pageSize || 10,
        siteId: this.site_id,
        ...this.searchParams
      };
      console.log(params);
      queryOilEntryRecord(params).then(res => {
        if (res.code === 0) {
          this.data = res.data;
          this.pagination = {
            ...this.pagination,
            total: res.count
          };
          this.tableLoading = false;
        }
      });
    },
    handleCurrentChange(current) {
      this.pagination.current = current;
      this.handleQuery({
        current,
        pageSize: 10
      });
    },
    handleExport() {
      let params = {
        siteId: this.site_id,
        ...this.searchParams
      };
      exportOilEntryRecord(params)
        .then(res => {
          this.$ExportFile(res, this.title);
        })
        .catch(err => {
          this.$message({
            message: err,
            type: 'error'
          });
        });
    },
    getTime(e) {
      if (e) {
        this.searchParams.begin = e[0];
        this.searchParams.end = e[1];
      } else {
        this.searchParams.begin = '';
        this.searchParams.end = '';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.text-danger {
  color: #f56c6c;
}

.text-info {
  color: #01adbe;
}
</style>
