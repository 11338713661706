export const columns = [
  {
    title: '序号',
    key: 'index',
    width: 64,
    scopedSlots: {
      customRender: 'index'
    }
  },
  {
    title: '加油站名称',
    key: 'site_name',
    align: 'left',
    minWidth: 100
  },
  {
    title: '油罐号',
    key: 'tank_num'
  },
  {
    title: '油品',
    key: 'oil_name'
  },
  {
    title: '进油量(L)',
    key: 'oil_sale'
  },
  {
    title: '时间',
    key: 'time'
  }
];
